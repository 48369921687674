var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', [_c('font-awesome-icon', {
    staticClass: "ml-2 mr-2",
    attrs: {
      "icon": "user"
    }
  }), _vm._v(_vm._s(_vm.name))], 1), _c('span', {
    staticClass: "float-right"
  }, [_c('font-awesome-icon', {
    staticClass: "ml-2 mr-2",
    attrs: {
      "icon": ['far', 'clock']
    }
  }), _vm._v(_vm._s(_vm.time))], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }