<template lang="pug">
.wuwow-card
  .wuwow-card-head
    //- Title
    h4.wuwow-card-title {{ $t('vipClassRecords') }}
  .wuwow-card-body
    //- FILTER 1.vip name 2.datetime range 3.class name
    vip-records-filter
    hr
    //- Table 10per datas
      row 1.vip name 2.class time 3.class name 4.consultant last teach time  5.other consultant last teach time 6.join class times
    vip-records-table

</template>

<script>
import vipRecordsTable from '@/components/consultant/vipRecords/class/table/index.vue';
import vipRecordsFilter from '@/components/consultant/vipRecords/class/filter/index.vue';
// i18n 需要請顧問翻譯
import i18n from '@/views/console/consultant/vipRecords/lang';
export default {
  name: 'VipClassRecords',

  components: {
    vipRecordsTable,
    vipRecordsFilter,
  },

  i18n,
};
</script>

