<template lang="pug">
div
  normal-data-table(
    :columns="columns"
    :rows="rows"
  )
    //- row 1.vip name 2.class time 3.class name 4.consultant last teach time  5.other consultant last teach time 6.join class times
    template(slot="table-row" slot-scope="props")
      span(v-if="props.data.column.field === slotFields.vipName")
        span {{ props.data.row.vipName }}
      span(v-if="props.data.column.field === slotFields.className")
        span {{ props.data.row.className }}
      span(v-if="props.data.column.field === slotFields.classTime")
        span {{ props.data.row.classTime }}
      span(v-if="props.data.column.field === slotFields.lastTaughtAt")
        consultantTaughtTimeContent(:name="props.data.row.lastTaughtAt.currentConsultant.name" :time="props.data.row.lastTaughtAt.currentConsultant.time")
        b-button.mt-2(v-b-toggle="`collapse-${props.data.row.id}`" size="sm") {{ $t('otherConsultant') }}
        b-collapse(:id="`collapse-${props.data.row.id}`")
          b-card.mt-2
            div(v-for="(info, index) in props.data.row.lastTaughtAt.otherConsultant" :key="index")
              consultantTaughtTimeContent(:name="info.name" :time="info.time")
      span(v-if="props.data.column.field === slotFields.classesTaken")
        //- 可以加上課堂數越多顏色越深 ex: 綠 0~50 藍 50~150 橘 150~350 紅 350以上
        span {{ props.data.row.classesTaken }}
      div(v-if="props.data.column.field === slotFields.operate")
        b-button.ml-2.mt-2(variant="primary" size="sm" v-b-modal.addStudentPerformanceModal)
          font-awesome-icon(icon="pen")
  add-student-performance-modal
</template>

<script>
import addStudentPerformanceModal from '@/components/consultant/vipRecords/performance/modal/addStudentPerformance/index.vue';
import consultantTaughtTimeContent from '@/components/consultant/vipRecords/class/table/consultantTaughtTimeContent/index.vue';
import normalDataTable from '@/components/table/normalDataTable/index.vue';
import i18n from '@/views/console/consultant/vipRecords/lang';

const tableColumnsFields = { vipName: 'vipName', className: 'className', classTime: 'classTime', lastTaughtAt: 'lastTaughtAt', classesTaken: 'classesTaken', operate: 'operate' };

export default {
  name: 'VipClassRecordsTable',

  components: {
    addStudentPerformanceModal,
    consultantTaughtTimeContent,
    normalDataTable,
  },

  data() {
    return {
      slotFields: {
        vipName: tableColumnsFields.vipName,
        className: tableColumnsFields.className,
        classTime: tableColumnsFields.classTime,
        lastTaughtAt: tableColumnsFields.lastTaughtAt,
        classesTaken: tableColumnsFields.classesTaken,
        operate: tableColumnsFields.operate,
      },
      columns: [
        {
          label: `Vip ${this.$t('name')}`,
          field: tableColumnsFields.vipName,
        },
        {
          label: this.$t('className'),
          field: tableColumnsFields.className,
        },
        {
          label: this.$t('classTime'),
          field: tableColumnsFields.classTime,
        },
        {
          label: this.$t('lastTaughtAt'),
          field: tableColumnsFields.lastTaughtAt,
        },
        {
          label: this.$t('classesTaken'),
          field: tableColumnsFields.classesTaken,
          tdClass: 'text-center',
          type: 'number',
        },
        {
          label: this.$t('operate'),
          field: tableColumnsFields.operate,
        },
      ],
      // TODO 假資料，後續串接API By Asa
      rows: [
        {
          id: 1,
          vipName: 'John',
          className: '2022-05-12 1330_Jessa Pareno',
          classTime: '2022-05-12 13:30',
          lastTaughtAt: { // 資料格式需跟後端要求
            currentConsultant: {
              name: 'Jessa Pareno',
              time: '2022-05-07 13:30',
            },
            otherConsultant: [
              { // 時間需排序
                name: 'Niki Lacson',
                time: '2022-04-30 13:30',
              },
              {
                name: 'Puppy Lia',
                time: '2022-02-28 13:30',
              },
            ],
          },
          classesTaken: 12,
        },
        {
          id: 2,
          vipName: 'Jane',
          className: '2022-05-07 1430_Niki Lacson',
          classTime: '2022-05-07 14:30',
          lastTaughtAt: { // 資料格式需跟後端要求
            currentConsultant: {
              name: 'Niki Lacson',
              time: '2022-05-11 13:30',
            },
            otherConsultant: [
              { // 時間需排序
                name: 'Doris Lacson',
                time: '2022-04-30 13:30',
              },
              {
                name: 'Father Pareno',
                time: '2022-02-28 13:30',
              },
            ],
          },
          classesTaken: 230,
        },
        {
          id: 3,
          vipName: 'Susan',
          className: '2022-05-08 1530_Jessa Pareno',
          classTime: '2022-05-08 15:30',
          lastTaughtAt: { // 資料格式需跟後端要求
            currentConsultant: {
              name: 'Jessa Pareno',
              time: '2022-05-07 13:30',
            },
            otherConsultant: [
              { // 時間需排序
                name: 'Niki Lacson',
                time: '2022-04-30 13:30',
              },
              {
                name: 'Jessa Pareno',
                time: '2022-02-28 13:30',
              },
            ],
          },
          classesTaken: 150,
        },
        {
          id: 4,
          vipName: 'Chris',
          className: '2022-05-09 1730_Jojo Liang',
          classTime: '2022-05-09 17:30',
          lastTaughtAt: { // 資料格式需跟後端要求
            currentConsultant: {
              name: 'Jojo Liang',
              time: '2022-05-11 13:30',
            },
            otherConsultant: [
              { // 時間需排序
                name: 'Poop pipi',
                time: '2022-04-30 13:30',
              },
              {
                name: 'Dogo hoha',
                time: '2022-02-28 13:30',
              },
            ],
          },
          classesTaken: 457,
        },
        {
          id: 5,
          vipName: 'Dan',
          className: '2022-05-10 1830_Andy Liang',
          classTime: '2022-05-10 18:30',
          lastTaughtAt: { // 資料格式需跟後端要求
            currentConsultant: {
              name: 'Andy Liang',
              time: '2022-05-11 13:30',
            },
            otherConsultant: [
              { // 時間需排序
                name: 'Like you',
                time: '2022-04-30 13:30',
              },
              {
                name: 'Dont like',
                time: '2022-02-28 13:30',
              },
            ],
          },
          classesTaken: 333,
        },
        {
          id: 6,
          vipName: 'John',
          className: '2022-05-10 0830_Niki Lacson',
          classTime: '2022-05-10 0830',
          lastTaughtAt: { // 資料格式需跟後端要求
            currentConsultant: {
              name: 'Niki Lacson',
              time: '2022-05-11 13:30',
            },
            otherConsultant: [
              { // 時間需排序
                name: 'Lala coe',
                time: '2022-04-30 13:30',
              },
              {
                name: 'Ponda chui',
                time: '2022-02-28 13:30',
              },
            ],
          },
          classesTaken: 99,
        },
      ],
    };
  },

  i18n,
};
</script>
