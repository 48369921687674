<template lang="pug">
div
  span
    font-awesome-icon.ml-2.mr-2(icon="user")
    | {{ name }}
  span.float-right
    font-awesome-icon.ml-2.mr-2(:icon="['far', 'clock']")
    | {{ time }}
</template>

<script>
export default {
  name: 'ConsultantTaughtTimeContent',

  props: {
    name: {
      type: String,
      default() {
        return '';
      },
    },
    time: {
      type: String,
      default() {
        return '';
      },
    },
  },
};
</script>
